<template>
    <div class="home_marriage_test_payment" id="agentTitle">
        <header>
             <!-- 
            备注：
            倒计时修改
            评论修改

             -->
            <img src="http://cdn.zhiyileiju.cn/WechatIMG474.jpeg" alt="">
            <!-- <h1><img src="http://cdn.zhiyileiju.cn/home_marriage_test_payment_h1.jpg " alt=""></h1> -->
            <!-- <h2>能找到真正爱你的人吗? <br> 最终会有好结果吗？</h2>
            <div class="box">
                <div class="top_box__">
                    <div class="center_box">
                        <div class="bottom_box">
                            <p>亲爱的<span>{{name}}：</span></p>
                            <p>从测算结果来看，<span>2021年底</span>你的姻缘将会出现特别重要的<span>婚姻感情转机</span>！</p>
                        </div>
                    </div>
                </div>
            </div> -->
        </header>
        <section>
            <div class="top_box__">
                <div class="card_box" style="border-radius: .1rem;">
                    <div class="card_top_box">
                        <h1>你的配对结果已生成</h1>
                    </div>
                    <div class="img_content_box__">
                        <div class="img_box__">
                            <span class="name_text__">{{man_value}}</span>
                        </div>
                        <div class="img_box__"></div>
                        <div class="img_box__">
                            <span class="name_text__">{{girl_value}}</span>
                        </div>
                    </div>
                    <div class="card_content_box">
                        <h2> <span>限时优惠￥{{price.pairname_amount}}元</span>  <i>距优惠结束还剩</i> </h2>
                        <p style="padding-bottom:.28rem;margin-top: -0.1rem;"><i style="color:#979797;">原价￥{{price.fake_amount}}元</i> 
                            <span class="van-count-down_box__" >
                                <!-- <van-count-down @change="timeTrigger" millisecond :time="time" format="HH:mm:ss:SS" style="color:#C91C18"/> -->
                                <van-count-down :time="time" millisecond @change="timeTrigger">
                                    <template #default="timeData">
                                        <span class="block">{{ '0'+timeData.hours }}</span>
                                        <span class="colon">:</span>
                                        <span class="block">{{ '0'+timeData.minutes }}</span>
                                        <span class="colon">:</span>
                                        <span class="block">{{ timeData.seconds }}</span>
                                        <span class="colon">:</span>
                                        <span class="block">{{ parseInt(timeData.milliseconds / 10) }}</span>
                                        <!-- <span class="colon text">后失效</span> -->
                                    </template>	
                                </van-count-down>
                            </span>
                        </p>
                        <h3><i>你和Ta的缘分深浅，一看便知</i></h3>
                        <div class="buttom_box" style="background: #04C967;" @click="paymentButtom('wechat')">
                            <img src="http://cdn.zhiyileiju.cn/WechatIMG480.jpeg" alt="">
                            <!-- <p> <img src="http://cdn.zhiyileiju.cn/wechaticon111.png" alt=""> 微信支付</p> -->
                        </div>
                        <div class="buttom_box" style="background: #1B95D4;margin-top:.3rem;" @click="paymentButtom('alipay')" v-if="!this.is_weixn">
                             <img src="http://cdn.zhiyileiju.cn/WechatIMG481.jpeg" alt="">
                            <!-- <p> <img src="http://cdn.zhiyileiju.cn/alipayicon111.png" alt=""> 支付宝</p> -->
                        </div>
                        <p class="bottom_p" style="margin-top: 0.5rem;"><i>已有<strong>9, 789, 316</strong> 人查看结果，帮助他们找到美满婚姻</i></p>
                        <p class="bottom_p" style="margin-bottom:.1rem"><i><strong>99.7%</strong>的用户认为对他们的婚姻生活很有帮助</i></p>
                        <div class="icon_brand">
                            <img src="http://cdn.zhiyileiju.cn/baozhang.png" alt="">
                        </div>
                        <p class="bottom_p_icon_brand">支付系统已通过安全联盟认证，答案仅自己可见</p>
                    </div>
                </div>
                <h3 style="font-weight:400">支付完成后，为您解锁8大重要内容</h3>
                <!-- <p class="title_p__">资深专家团队倾力打造</p> -->
                <div class="card_box">
                    <div class="card_top_box">
                        <h1>双方名字及恋爱性格分析</h1>
                    </div>
                    <div class="text_dim_box">
                        <div class="shadow_text_box">
                            <p>
                                分类：到哦分红二十年后飞机场i欧凡参加覅色戒出来看而快乐五：十分你来南非而窝囊废就能发顺  丰单号呢发货偶
                                尔发货合肥和
                            </p>
                            <p>分类：到i：哦分红二十年后飞机场i欧凡参加覅色戒出来看
                                而快乐五十分你来南非而窝囊废就能发顺  丰单号呢发货
                                分类：到i哦分红二十年后飞机场i欧凡参加覅色戒出来看而
                                快乐五：十分你来南非而窝囊废就能发顺  丰单号呢发货偶
                                尔发货合肥和.
                            </p>
                            <p>
                                而快乐五十分你来南非而窝囊废就能发顺  丰单号呢发货是
                                而快乐五十分你来南非而窝囊废就能发顺  丰单号呢发货是
                                尔发货合肥和.分类：到i哦分红二十年后飞机场i戒出来看而
                                快乐五：十分你来南非而窝囊废就能发顺  丰单号呢发货丰单号呢发货发货发货
                            </p>
                        </div>
                    </div>
                    <div class="suspend_text_box">
                        <p style="margin-top: .5rem;">1、双方名字组合的五格笔画</p>
                        <p>2、你的基本性格和爱情观念</p>
                        <p>3、Ta的基本性格和爱情观念</p>
                    </div>
                   <div class="buttom_box buttom_box_text__ " style="background: #c4393e;" @click="backToTheTopCallback">
                        <p> <img src="http://cdn.zhiyileiju.cn/1421633941854_.pic_hd.jpg" alt="">  解锁查看配对结果</p>
                    </div>
                </div>
                <div class="card_box">
                    <div class="card_top_box">
                        <h1>双方感情现状及幸福建议</h1>
                    </div>
                    
                    <div class="text_dim_box">
                        <div class="shadow_text_box">
                            <p>
                                分类：到哦分红二十年后飞机场i欧凡参加覅色戒出来看而快乐五：十分你来南非而窝囊废就能发顺  丰单号呢发货偶
                                尔发货合肥和
                            </p>
                            <p>分类：到i：哦分红二十年后飞机场i欧凡参加覅色戒出来看
                                而快乐五十分你来南非而窝囊废就能发顺  丰单号呢发货
                                分类：到i哦分红二十年后飞机场i欧凡参加覅色戒出来看而
                                快乐五：十分你来南非而窝囊废就能发顺  丰单号呢发货偶
                                尔发货合肥和.
                            </p>
                            <p>
                                而快乐五十分你来南非而窝囊废就能发顺  丰单号呢发货是
                                而快乐五十分你来南非而窝囊废就能发顺  丰单号呢发货是
                                尔发货合肥和.分类：到i哦分红二十年后飞机场i戒出来看而
                                快乐五：十分你来南非而窝囊废就能发顺  丰单号呢发货丰单号呢发货发货发货
                            </p>
                        </div>
                    </div>
                    <div class="suspend_text_box">
                        <p style="margin-top: .5rem;">1、说准你当前的感情状况</p>
                        <p>2、双方对待感情的态度如何</p>
                        <p>3、哪些因素干扰你们的感情</p>
                    </div>
                   <div class="buttom_box buttom_box_text__" style="background: #c4393e;" @click="backToTheTopCallback">
                        <p> <img src="http://cdn.zhiyileiju.cn/1421633941854_.pic_hd.jpg" alt=""> 解锁查看配对结果</p>
                    </div>
                </div>
                <div class="card_box">
                    <div class="card_top_box">
                        <h1>你们感情容易遇到的问题</h1>
                    </div>
                    
                    <div class="text_dim_box">
                        <div class="shadow_text_box">
                            <p>
                                分类：到哦分红二十年后飞机场i欧凡参加覅色戒出来看而快乐五：十分你来南非而窝囊废就能发顺  丰单号呢发货偶
                                尔发货合肥和
                            </p>
                            <p>分类：到i：哦分红二十年后飞机场i欧凡参加覅色戒出来看
                                而快乐五十分你来南非而窝囊废就能发顺  丰单号呢发货
                                分类：到i哦分红二十年后飞机场i欧凡参加覅色戒出来看而
                                快乐五：十分你来南非而窝囊废就能发顺  丰单号呢发货偶
                                尔发货合肥和.
                            </p>
                            <p>
                                而快乐五十分你来南非而窝囊废就能发顺  丰单号呢发货是
                                而快乐五十分你来南非而窝囊废就能发顺  丰单号呢发货是
                                尔发货合肥和.分类：到i哦分红二十年后飞机场i戒出来看而
                                快乐五：十分你来南非而窝囊废就能发顺  丰单号呢发货丰单号呢发货发货发货
                            </p>
                        </div>
                    </div>
                    <div class="suspend_text_box">
                        <p style="margin-top: .5rem;">1、哪些问题容易导致你们吵架或分手</p>
                        <p>2、你们三观合吗？</p>
                        <p>3、是否容易遇到情感问题？</p>
                    </div>
                   <div class="buttom_box buttom_box_text__" style="background: #c4393e;" @click="backToTheTopCallback">
                        <p> <img src="http://cdn.zhiyileiju.cn/1421633941854_.pic_hd.jpg" alt="">  解锁查看配对结果</p>
                    </div>
                </div>
                <div class="card_box">
                    <div class="card_top_box">
                        <h1>双方感情结果及幸福建议</h1>
                    </div>
                    
                    <div class="text_dim_box">
                        <div class="shadow_text_box">
                            <p>
                                分类：到哦分红二十年后飞机场i欧凡参加覅色戒出来看而快乐五：十分你来南非而窝囊废就能发顺  丰单号呢发货偶
                                尔发货合肥和
                            </p>
                            <p >分类：到i：哦分红二十年后飞机场i欧凡参加覅色戒出来看
                                而快乐五十分你来南非而窝囊废就能发顺  丰单号呢发货
                                分类： 到i哦分红二十年后飞机场i欧凡参加覅色戒出来看而
                                快乐五：十分你来南非而窝囊废就能发顺  丰单号呢发货偶
                                尔发货合肥和.
                            </p>
                            <p>
                             而快乐五十分你来南非而窝囊废就能发顺  丰单号呢发货是
                                而快乐五十分你来南非而窝囊废就能发顺  丰单号呢发货是
                                尔发货合肥和.分类：到i哦分红二十年后飞机场i戒出来看而 
                                快乐五：十分你来南非而窝囊废就能发顺  丰单号呢发货丰单号呢发货发货发货
                            </p>
                        </div>
                    </div>
                    <div class="suspend_text_box">
                        <p style="margin-top: .5rem;">1、Ta是能跟你长相厮守的人吗</p>
                        <p>2、你们感情最终能甜蜜相守吗</p>
                        <p>3、复合或分开能实现吗，对自己好吗</p>
                    </div>
                   <div class="buttom_box buttom_box_text__" style="background: #c4393e;" @click="backToTheTopCallback">
                        <p> <img src="http://cdn.zhiyileiju.cn/1421633941854_.pic_hd.jpg" alt="">  解锁查看配对结果</p>
                    </div>
                </div>
                <div class="card_box">
                    <div class="card_top_box">
                        <h1>当前感情升温办法</h1>
                    </div>
                    
                    <div class="text_dim_box">
                        <div class="shadow_text_box">
                            <p>
                                分类：到哦分红二十年后飞机场i欧凡参加覅色戒出来看而快乐五：十分你来南非而窝囊废就能发顺  丰单号呢发货偶
                                尔发货合肥和
                            </p>
                            <p>分类：到i：哦分红二十年后飞机场i欧凡参加覅色戒出来看
                                而快乐五十分你来南非而窝囊废就能发顺  丰单号呢发货
                                分类：到i哦分红二十年后飞机场i欧凡参加覅色戒出来看而
                                快乐五：十分你来南非而窝囊废就能发顺  丰单号呢发货偶
                                尔发货合肥和.
                            </p>
                            <p>
                                而快乐五十分你来南非而窝囊废就能发顺  丰单号呢发货是
                                而快乐五十分你来南非而窝囊废就能发顺  丰单号呢发货是
                                尔发货合肥和.分类：到i哦分红二十年后飞机场i戒出来看而
                                快乐五：十分你来南非而窝囊废就能发顺  丰单号呢发货丰单号呢发货发货发货
                            </p>
                        </div>
                    </div>
                    <div class="suspend_text_box">
                        <p style="margin-top: .5rem;">1、如何增加好桃花，脱单攻略</p>
                        <p>2、如何把握住幸福，复合办法</p>
                        <p>3、提升婚姻质量，感情甜蜜指数</p>
                    </div>
                   <div class="buttom_box buttom_box_text__" style="background: #c4393e;" @click="backToTheTopCallback">
                        <p> <img src="http://cdn.zhiyileiju.cn/1421633941854_.pic_hd.jpg" alt="">  解锁查看配对结果</p>
                    </div>
                </div>
                <div class="card_box">
                    <div class="card_top_box">
                        <h1>最适合你的婚后感情建议</h1>
                    </div>
                    
                    <div class="text_dim_box">
                        <div class="shadow_text_box">
                            <p>
                                分类：到哦分红二十年后飞机场i欧凡参加覅色戒出来看而快乐五：十分你来南非而窝囊废就能发顺  丰单号呢发货偶
                                尔发货合肥和
                            </p>
                            <p>分类：到i：哦分红二十年后飞机场i欧凡参加覅色戒出来看
                                而快乐五十分你来南非而窝囊废就能发顺  丰单号呢发货
                                分类：到i哦分红二十年后飞机场i欧凡参加覅色戒出来看而
                                快乐五：十分你来南非而窝囊废就能发顺  丰单号呢发货偶
                                尔发货合肥和.
                            </p>
                            <p>
                                而快乐五十分你来南非而窝囊废就能发顺  丰单号呢发货是
                                而快乐五十分你来南非而窝囊废就能发顺  丰单号呢发货是
                                尔发货合肥和.分类：到i哦分红二十年后飞机场i戒出来看而
                                快乐五：十分你来南非而窝囊废就能发顺  丰单号呢发货丰单号呢发货发货发货
                            </p>
                        </div>
                    </div>
                    <div class="suspend_text_box">
                        <p style="margin-top: .5rem;">1、如何在婚后控制好自己的情绪</p>
                        <p>2、如何在婚后可取悦对方</p>
                        <p>3、如何在婚后增旺婚姻运势</p>
                    </div>
                   <div class="buttom_box buttom_box_text__" style="background: #c4393e;" @click="backToTheTopCallback">
                        <p> <img src="http://cdn.zhiyileiju.cn/1421633941854_.pic_hd.jpg" alt="">  解锁查看配对结果</p>
                    </div>
                </div>
                
                <div class="card_box">
                    <div class="card_top_box">
                        <h1 style="margin-bottom:.36rem">2022版姓名配对 好评如潮</h1>
                    </div>
                    
                    <Comments />
                    <p style="text-align:center;margin-bottom: .3rem;">最新支付订单</p>
                    <!-- <orderscroll :prices='price'/> -->
                    <orderscroll :prices='price'> </orderscroll>
                </div>
            </div>
            <h4 class="bottom_title">资深专家团队倾力打造</h4>
        </section>
        <footer>
            <div class="top_brand">
                <div class="img_box" style="width: 1.56rem"><img src="http://cdn.zhiyileiju.cn/home_marriage_test_brand_3.jpg" alt=""></div>
                <div class="img_box" style="width: 1.7rem"><img src="http://cdn.zhiyileiju.cn/home_marriage_test_brand_2.jpg" alt=""></div>
                <div class="img_box" style="width: 2.51rem"><img src="http://cdn.zhiyileiju.cn/home_marriage_test_brand_1.jpg" alt=""></div>
            </div>
            <div class="buttom_anchor" v-if="fixedbtn" @click="backToTheTopCallback('anchor')">
                点击查看全部配对结果
            </div>
        </footer>
        <van-overlay :show="shopPayConfirm" z-index="10" lock-scroll>
        <!-- <van-overlay :show="true" z-index="10" lock-scroll> -->
            <div class="wrapper center" @click.stop>
                <div class="wrapper center" @click.stop>
                    <div class="block">
                        <div class="title">离双方配对报告只差1步<br></div>
                        <div class="title title_tow">支付后查看契合度报告</div>
                        <div class="bg_box_chunk_">
                            <span class="only_remain_text">仅剩{{percentage_num}}%</span>
                            <div class="img_bg_box"></div>
                            <div class="bottom_text_box__">
                                <span class="bottom_text__">信息提交</span>
                                <span class="bottom_text__">生成报告</span>
                                <span class="bottom_text__">查看契合度</span>
                            </div>
                        </div>
                        <div class="payed payment_" @click="paymentButtoms('')"><img src="http://cdn.zhiyileiju.cn/WechatIMG600.jpeg" alt=""></div>
                        <div class="payed unpay_" @click="unpay">{{popup_text}}</div>
                        <div class="payed  Dcheckorder_" @click="Dcheckorder">已完成支付</div>
                        <div class="count_down_box_">
                            <van-count-down :time="counttime" millisecond>
                                <template #default="timeData">
                                    <span class="block_">{{ timeData.hours }}</span>
                                    <span class="colon">:</span>
                                    <span class="block_">{{ timeData.minutes }}</span>
                                    <span class="colon">:</span>
                                    <span class="block_">{{ timeData.seconds }}</span>
                                    <span class="colon">:</span>
                                    <span class="block_">{{ parseInt(timeData.milliseconds / 10) }}</span>
                                    <span class="colon text">后失效</span>
                                </template>	
                            </van-count-down>
                        </div>
                    </div>
                </div>
            </div>
        </van-overlay>
        <!--  投诉 -->
        <span  class="fixation_complaint" v-if="complaint_buttom_fal" @click="complaintButtom">
            <img src="https://cdn.zhiyileiju.cn/WechatIMG559.pic" alt="">
        </span>
    </div>
</template>

<script>
import Comments from "../../components/Comment.vue";
import orderscroll from "../../components/OrderScroll.vue";
import payment from "../../mixin/nameMatching/payment"

export default {
     mixins:[payment],
   components: {
       Comments,orderscroll
   }, 
}
</script>

<style lang="scss" scoped>
.home_marriage_test_payment{
    background: #ffeff6;
    // position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
    header{
        width: 7.5rem;
    }
    section{
        margin-top:-.4rem;
        .top_box__{
            width: 100%;
            margin-top: .36rem;
            .card_box{
                position: relative;
                width: 7.08rem;
                // background: #FFF0D3;
                background: #FFFFFF;
                border-radius: .3rem;
                margin: 0 auto;
                padding-bottom: .5rem;
                margin-bottom: .8rem;
                box-shadow: 0 0 5px #e8c7d6;
                overflow: hidden;
                .card_top_box{
                    margin-top: .27rem;
                    h1{
                        font-size: .38rem;
                        color: #D74A50;
                        text-align: center;
                    }
                }
                // .img_content_box__{
                //     text-align: center;
                //     margin-top: .4rem;
                //     .img_box__{
                //         position: relative;
                //         display: inline-block;
                //         width: 2.61rem;
                //         height: 3.45rem;
                //         overflow: hidden;
                //         .name_text__{
                //             position: relative;
                //             top: -.45rem;
                //             font-size: .36rem;
                //             color: #FFFFFF;
                //             background: #D45559;
                //             padding:.1rem .23rem .15rem .23rem;
                //             border-radius: 0px 0px .1rem .1rem;
                //         }
                //     }
                //     .img_box__:nth-child(1){
                //         background: url("http://cdn.zhiyileiju.cn/WechatIMG478.jpeg");
                //         background-size: 100% 100%;
                //     }
                //     .img_box__:nth-child(2){
                //         width: 1.29rem;
                //         background: url("http://cdn.zhiyileiju.cn/WechatIMG479.jpeg");
                //         background-size: 100% 100%;
                //     }
                //     .img_box__:nth-child(3){
                //         background: url("http://cdn.zhiyileiju.cn/WechatIMG477.jpeg");
                //         background-size: 100% 100%;
                //     }
                // }


                .img_content_box__{
                    text-align: center;
                    margin-top: .4rem;
                    display: flex;
                    justify-content: center;
                    .img_box__{
                        position: relative;
                        display: inline-block;
                        width: 2.61rem;
                        height: 3.45rem;
                        overflow: hidden;
                        .name_text__{
                            position: relative;
                            top: -.45rem;
                            font-size: .36rem;
                            color: #FFFFFF;
                            background: #D45559;
                            padding:.1rem .23rem .15rem .23rem;
                            border-radius: 0px 0px .1rem .1rem;
                        }
                    }
                    .img_box__:nth-child(1){
                        background: url("http://cdn.zhiyileiju.cn/WechatIMG478.jpeg");
                        background-size: 100% 100%;
                    }
                    .img_box__:nth-child(2){
                        width: 1.29rem;
                        background: url("http://cdn.zhiyileiju.cn/WechatIMG479.jpeg");
                        background-size: 100% 100%;
                    }
                    .img_box__:nth-child(3){
                        background: url("http://cdn.zhiyileiju.cn/WechatIMG477.jpeg");
                        background-size: 100% 100%;
                    }
                }
                p{
                    font-size: .28rem;
                    color: #C4393E;
                    // text-align: center;
                    span{
                        // margin-left: .28rem;
                    }
                    // border-bottom: 1px solid #FCAF93;
                }
                .top_p__{
                    background: #F8CAA6;
                    height: .87rem;
                    line-height: .87rem;
                    margin: 0 .32rem;
                    border-radius: .1rem;
                    text-indent: 1em;
                }
                .card_content_box{
                    // margin-top: .34rem;
                    // margin-top: -0.2rem;
                    padding:0 .32rem;
                    i{
                        font-size: .26rem;
                        color: #C98578;
                    }
                    h2{
                        position: relative;
                        height: .63rem;
                        font-weight: 500;
                        font-size: .34rem;
                        color: #A80009;
                        span{
                            display: inline-block;
                            height: .63rem;
                            // background: #C91C18;
                            border-radius: .1rem;
                            // padding: 0 .23rem;
                            line-height: .63rem;
                            color: #C4393E;
                        }
                        i{
                            position: absolute;
                            right: 0;
                            top: .1rem;
                            color: #323232;
                            font-weight: 400;
                            font-size:.26rem ;
                        }
                    }
                    p{
                        position: relative;
                        border-bottom: none;
                        padding-bottom: 0;
                        text-align: left;
                        margin-top: .1rem;
                        i{
                            text-decoration:line-through ;
                        }
                        .van-count-down_box__{
                            position: absolute;
                            right:0;
                            // margin-top: -0.12rem;
                            // transform:scale(.7);
                            .colon {
                                display: inline-block;
                                margin: 0 .04rem;
                                font-size: .2rem;
                                color: #C4393E;
                            }
                            .block {
                                display: inline-block;
                                width: .43rem;
                                color: #fff;
                                font-size: .24rem;
                                text-align: center;
                                background-color: #C4393E;
                                box-sizing:border-box;
                                border: 1px solid #C4393E;
                            }
                        }
                    }
                    h3{
                        margin-top:.2rem;
                        font-weight: 400;
                        text-align: center;
                        margin-bottom: .2rem;
                        i{
                            font-size: .28rem;
                            color: #525252;
                        }
                    }
                    .buttom_box{
                        width: 6.41rem;
                        // height: .95rem;
                        border-radius: .95rem;
                        cursor:pointer;
                        // margin-top:.47rem;
                        p{
                            position: relative;
                            text-align: center;
                            line-height: .95rem;
                            font-size: .36rem;
                            color: #FDFDFD;
                            padding-left: 1rem;
                            img{
                                position: absolute;
                                width: .70rem;
                                top: .12rem;
                                left: 1.8rem;       
                            }
                        }
                    }
                    .bottom_p{
                        text-align: center;
                        margin-top: .2rem;
                        i{
                            color: #525252;
                            text-decoration:none ;
                            strong{
                                color: #C4393E;
                                font-weight: 500;
                            }
                        }
                    }
                    .bottom_p_icon_brand{
                        margin-top: .04;
                        text-align: center;
                        font-size: .24rem;
                        color: #19AA0F;
                    }
                }
                .text_dim_box{
                    margin-top: .16rem;
                    .shadow_text_box{
                        // background: #c4dfc6;
                        background: rgb(255,246,239);
                        padding: 0.1rem;
                        width: 90%;
                        margin: auto;
                        border-radius: 0.1rem;
                    }
                    p{
                        padding: 0 .2rem;
                        color: transparent;
                        text-shadow: 2px 2px 3px #000;
                        font-size: .24rem;
                        text-align: left;
                        border-bottom: none;
                        line-height:.4rem;
                        letter-spacing:1px;
                    }
                }
                .suspend_text_box{
                    position: absolute;
                    top: 3rem;
                    left: 50%;
                    background: #fff;
                    transform: translate(-50%,-50%);
                    width: 5.65rem;
                    // height: 2.66rem;
                    padding-bottom: .5rem;
                    border-radius: .1rem;
                    p{
                        text-align: left;
                        border-bottom: none;
                        font-size: .28rem;
                        color: #323232;
                        margin-left: .5rem;
                        line-height: .6rem;
                    }
                }
                .buttom_box{
                    // position: absolute;
                    // top: 4.85rem;;
                    // left: 0;
                    // right: 0;
                    width: 5.69rem;
                    height: .91rem;
                    margin: auto;
                    border-radius: .15rem;
                    p{
                        position: relative;
                        text-align: center;
                        border:none;
                        line-height: .91rem;
                        color: #FFF0D3;
                        font-size: .36rem;
                        img{
                            position: absolute;
                            top: 27%;
                            left: 18%;
                            width: .36rem;
                            // margin-top: .3rem;
                        }
                        
                    }
                }
                .buttom_box_text__{
                    position: absolute;
                    top: 4.85rem;;
                    left: 0;
                    right: 0;
                    p{
                        text-indent: 1em;
                    }
                }
            }
            h3{
                margin-top: -.53rem;
                margin-bottom: .4rem;
                text-align: center;
                color: #525252;
                font-size: .32rem;
            }
            .title_p__{
                margin-top: .2rem;
                margin-bottom: .8rem;
                text-align: center;
                font-size:.28rem ;
                color: #FCAF93;
            }
           
        }
        .bottom_title{
            font-size: .4rem;
            font-weight: 400;
            color: #C4393E;
            text-align: center;
        }
    }
    footer{
        padding-bottom: 1.2rem;
        .top_brand{
            text-align: center;
            .img_box{
                display: inline-block;
                margin-right: .2rem;
            }
            .img_box:last-child{
                margin-right: 0;
            }
        }
        .buttom_anchor{
            position: fixed;
            bottom: 0;
            width: 7.5rem;
            text-align: center;
            line-height: 1.11rem;
            font-size: .36rem;
            background: #c91c18;
            color: #FEFBFB;
            height: 1.11rem;
            animation-name: myfirst;
            animation-duration: 1s;
            animation-iteration-count:infinite;
            -webkit-animation-iteration-count:infinite; /* Safari 和 Chrome */
        }
        @keyframes myfirst
         {
             0%   {transform:scale(1);}
             50%  {transform:scale(1.1)}
             100% {transform:scale(1)}
         }
    }
     .wrapper{
       width: 7.5rem;
       height: 100%;
       margin: 0 auto;
        .block {
            position: fixed;
            top:50%;
            left: 50%;
            margin: 0 auto;
            width: 5.5rem;
            height: 9.3rem;
            transform: translate(-50%,-50%);
            background-color: white;
            border-radius: .4rem;
            padding: 0.4rem 0;
            .title{
                color: #CF031A;
                text-align: center;
                font-size: .352rem;
                font-weight: bold;
                margin: 0;
                margin-top: .1rem;
            }
            .title_tow{
                margin-top: .2rem;
            }
            .bg_box_chunk_{
                overflow: hidden;
                margin-top: .9rem;
                .img_bg_box{
                    width: 4.48rem ;
                    height: .5rem ;
                    background:url('https://cdn.zhiyileiju.cn/WechatIMG599.pic') no-repeat ;
                    background-size: 100% 100%;
                    margin: 0 auto;
                    margin-top: 0.13rem;
                }
                .only_remain_text{
                    float: right;
                    font-size: .26rem;
                    color: #F5A625;
                    margin-right: .21rem;
                }
                .bottom_text_box__{
                    // position: relative;
                    overflow: hidden;
                    width: 5.5rem;
                    white-space : nowrap;
                    .bottom_text__{
                        // position: absolute;
                        float: left;
                        color: #525252;
                        font-size: .26rem;
                        margin-top: .29rem;
                    }
                    .bottom_text__:nth-child(1){
                        margin-left: .26rem;
                    }
                    .bottom_text__:nth-child(2){
                        margin-left: 1.02rem;
                    }
                   
                    .bottom_text__:nth-child(3){
                        margin-left: .4rem;
                    }
                    @media screen and (max-width: 340px) {
                        .bottom_text__:nth-child(3){
                            margin-left: .2rem;
                        }
                    }
                }
            }
            .payed{
                width: 4.5rem;
                height: .98rem;
                font-size: .38rem;
                border-radius: 49px;
                text-align: center;
                margin: 0 auto;
                line-height: .98rem;
            }
            .payment_{
                margin-top: 1.09rem;
                color: #fff;
                background: #CF031A;
            }
            .unpay_{
                margin-top: .31rem;
                color: #fff;
                background: #F5A625;
            }
            .Dcheckorder_{
                margin-top: .31rem;
                box-sizing: border-box;
                border: 1px solid #F5A625;
            }
            .count_down_box_{
                text-align: center;
                margin-top: .4rem;
                .colon {
                    display: inline-block;
                    margin: 0 .04rem;
                    font-size: .2rem;
                    color: #b63437;
                }
                .block_ {
                    display: inline-block;
                    width: .43rem;
                    color: #fff;
                    font-size: .24rem;
                    text-align: center;
                    background-color: #b63437;
                    box-sizing:border-box;
                    border: 1px solid #fa455f;
                }
                .text{
                    margin-left: .1rem;
                    font-size: .14rem;
                    color: #525252;
                }   
            }
        }
    }
     //投诉
        .fixation_complaint{
            position: fixed;
            width: .48rem;
            top: 3.02rem;
            right: 0;
            z-index: 10;
        }
}
</style>
<style lang="scss">
    .home_marriage_test_payment{
        .van-popup{
            background: none;
        }
    }
</style>
