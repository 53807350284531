import { $order,$getSalesPrice,$orderConfirm,$payment,$ekaf,$paymentQuery,$paymentWechatconf } from "../../api/home_marriage_test"
import { $stat } from "../../api/stat"
const payment = {
    data () {
        return {
            complaint_buttom_fal:true, //投诉按钮开关
            popup_text:'领取优惠红包',
            percentage_num:0.8,
            counttime:10*60*1000,//倒计时
            fixedbtn:false, //是否隐藏底部付费解锁全部内容按钮
            userinfo:{},//用户信息
            man_value:'',//男姓名
            girl_value:'',//女姓名
            price:{},//价格
            time:localStorage.getItem("time"),//计时器
            // time:600000,//计时器
            data_list:[], //动态用户列表数据
            shopPayConfirm: false,//请确认支付是否完成弹窗
            // shopPayConfirm: true,//请确认支付是否完成弹窗
            type:localStorage.getItem("type"),
            is_pay:"",//是否支付
            wechat_pay_key:1,
            
        }
    },
    created () {
        if ( localStorage.getItem('red_packet_type') === '2' ) {
            if (this.is_weixn) {
                let from_arr_ = ['dbpd','xfpd','xfpd2','zbpd','rmpd1','rmpd2','dgpd'];
                if ( from_arr_.indexOf(this.$route.query.from) === -1) {
                    this.popup_text = '测算我的婚姻运势'
                }else{
                    this.popup_text = '姓名测试'
                }
            }else{
                this.popup_text = '测算我的婚姻运势'
            }
            // this.popup_text = '测算我的婚姻运势';
            localStorage.setItem("judge_indicates_which",1);
        }else{
            this.popup_text = '领取优惠红包'
        }
        if ( localStorage.getItem('red_packet_type') === null) {
           
        }else{
            this.percentage_num = 0.7
        }
        // console.log((this.$route.path).split('/')[1])
        
        this.dataRequest()
        this.confirmPaymentPopup() 
        window.addEventListener("scroll", this.handleScroll);
        sessionStorage.setItem("user_id",this.$route.params.id);
    },
    mounted () {
        this.getWxConfig()
        $stat("v1_name_atching_pay");
        this.confirmPaymentPopup() 
       
    },
    computed: {
        is_weixn() {
            var ua = navigator.userAgent.toLowerCase();
            if (ua.match(/MicroMessenger/i) == "micromessenger") {
                return true;
            } else {
                return false;
            }
        },
    },
    methods:{
        getWxConfig(){//微信内支付配置
            // let url = window.location.href;
                $paymentWechatconf({url:window.location.href}).then(res => {
                    console.log('wx',res.data.data.data)
                    let wxconfig = res.data.data.data;
                    if (this.is_weixn) {
                        wx.config({
                            debug: false,
                            appId: wxconfig.appId,
                            timestamp: wxconfig.timestamp,
                            nonceStr: wxconfig.nonceStr,
                            signature: wxconfig.signature,
                            jsApiList: ['chooseWXPay']
                        });
                    }
                })
        },
        confirmPaymentPopup() {//通过接口判断确认支付弹框
            setTimeout(() => {
                if ( localStorage.getItem("type") !== '10' ){
                    // this.shopPayConfirm = true;//确认支付弹框关闭
                    if (this.is_weixn) {
                        if ( this.wechat_pay_key === 1 ) {
                            this.shopPayConfirm = false
                        }else{
                            this.shopPayConfirm = true
                        }
                    }else{
                        this.shopPayConfirm = true;//确认支付弹框打开
                    }
                    $paymentQuery({ paytype: localStorage.getItem("type"),order_id: this.$route.params.id}).then( (res) => {
                        this.is_pay = res.data.data.is_pay
                        if( res.data.data.is_pay ) {//判断用户已支付打开弹窗，跳转支付成页
                            this.shopPayConfirm = true;//确认支付弹框关闭

                            let arr_from_ = ['hytonp1','nptohy1','kppd','cdpd','kphy','cdhy']
                            if( arr_from_.indexOf(this.$route.query.from) === -1 ) {
                                window.location.replace(`/name_matching/payment/succeed/${this.$route.params.id}/${localStorage.getItem("type")}?from=${this.$route.query.from}`)
                            }else{
                                //直接跳结果
                                window.location.replace(`/name_matching/payment/result/${this.$route.params.id}/${localStorage.getItem("type")}?from=${this.$route.query.from}`)
                            }
                            // window.location.replace(`/name_matching/payment/succeed/${this.$route.params.id}/${localStorage.getItem("type")}?from=${this.$route.query.from}`)
                        }else{//用户没支付
                            if ( this.type == 10 ) { //支付方式
                                this.shopPayConfirm = false;//确认支付弹框关闭
                            }else{
                                if (this.is_weixn) {
                                    if ( this.wechat_pay_key === 1 ) {
                                        this.shopPayConfirm = false
                                    }else{
                                        this.shopPayConfirm = true
                                    }
                                }else{
                                    this.shopPayConfirm = true;//确认支付弹框打开
                                }
                            }
                        }
                    } );   
                }
            }, 1000);
        },
        backToTheTopCallback (type) {//锚点
            if ( type === 'anchor') {
                $stat("v1_name_atching_payment_anchor")
            }else{
                $stat("v1_name_atching_payment_unlock")
            }
            document.querySelector("#agentTitle").scrollIntoView({behavior: "smooth", block: "start" });
        },
        handleScroll() {//y页面向下拉300时显示按钮
			var scrollTop =
				window.pageYOffset || 
				document.documentElement.scrollTop ||
				document.body.scrollTop;
			if (scrollTop > 300) {
				this.fixedbtn = true;
			} else {
				this.fixedbtn = false;
			}
		},
        saveTheLinkCallback(){ //三次挽回链接
            if (this.is_weixn) {
                let from_arr_ = ['dbpd','xfpd','xfpd2','zbpd','rmpd1','rmpd2','dgpd'];
                let from_arr1_ = ['lndbgd','lnxf1','lnxf3','lnrt1','lnrt2'];
                switch ( -1 ) {
                    case from_arr_.indexOf(this.$route.query.from) === -1 ? from_arr_.indexOf(this.$route.query.from)+1 : from_arr_.indexOf(this.$route.query.from) - (from_arr_.indexOf(this.$route.query.from) + 1) :
                            window.location.replace(`https://jx2.gyqingqing.com/wechatmerry?fromk=hjgw3cm`)
                            //  https://jx2.gyqingqing.com/wechatmerry?fromk=ljgw3cm
                        break;
                    case from_arr1_.indexOf(this.$route.query.from) === -1 ? from_arr1_.indexOf(this.$route.query.from)+1 : from_arr1_.indexOf(this.$route.query.from) - (from_arr1_.indexOf(this.$route.query.from) + 1) :
                            window.location.replace(`https://jx2.gyqingqing.com/wechatmerry?fromk=ljgw3cm`)
                        break;
                
                    default:
                        window.location.replace(`https://wechat.nnabc.cn/api/v1/wechat/entry/${localStorage.getItem("fromk")}/4`)
                        break;
                }
            }else{
                window.location.replace(`https://huyin.zhiyileiju.cn/home_marriage_test?from=w3hc`)
                // window.location.replace(`https://huyin.zhiyileiju.cn/home_marriage_test?from=w3hc&kf=${res.data.data.hanzimean.xm}&sex=${res.data.data.sex === '男' ? '1': '2'}&birth=公历:${lunar_calendar_value.cYear}年${lunar_calendar_value.cMonth}月${lunar_calendar_value.cDay}日${lunar_calendar_value.tiem_}时&dateObj=${JSON.stringify(lunar_calendar_value)}`)
            }
        },
        dataRequest () {//数据请求
            $order(this.$route.params.id).then( ( res ) => {//获取用户信息
                this.userinfo = res.data.data
                this.man_value = res.data.data.hanzimean.xm//男姓名
                this.girl_value = res.data.data.pair_name//女姓名
                let date = res.data.data.pub_birth_date
                this.userinfo.time_of_birth = `${date.substr(0,4)}年${date.substr(5,2)}月${date.substr(8,2)}日${date.substr(11,2)}时`
            }) 
            let postdata = {//$ekaf接口参数
                type: 2,
                perPage: 15,
                page: 1,
                atype:2,
                fromk:this.$route.query.from
            }
            $getSalesPrice({fromk:this.$route.query.from}).then( ( res ) => {//获取价格
                this.price = res.data.data[0]
            })
            setTimeout(() => {
                $ekaf(postdata).then( ( res ) => { //支付信息接口
                if ( res.status === 200 ){
                    this.data_list = res.data.data
                    for ( let i = 0; i < this.data_list.length; i++ ) {
                        this.data_list[i].price = this.price.pairname_amount 
                        this.data_list[i].name = (this.data_list[i].user).slice(0,-4)
                        this.data_list[i].designation = (this.data_list[i].user).substr(-4,4)
                    }
                    this.timimgToast()
                }
            })
            })
        },
        timeTrigger (TimeData) {//计时器变化触发(支付倒计时)
            //每次触发计时器后存到本地储存里，保证页面刷新计时器不重新开始
            let MM = TimeData.minutes * 60
            let ss = TimeData.seconds + MM
            let mss_ = TimeData.milliseconds + (ss*1000)
            localStorage.setItem("time",mss_);
        },
        paymentButtom (payment_type) {//支付
            if ( payment_type === '' ) {
                payment_type = localStorage.getItem("type") === '2' ? 'wechat' : 'alipay'
            }else{
                if(payment_type === "alipay"){
                    $stat("v1_name_atching_pay_alipay");
                }else{
                    $stat("v1_name_atching_pay_wechat");
                }
            }
            localStorage.setItem("type",payment_type === "alipay" ? 1 : 2); //将支付类型存到本地
            if ( this.type == 10 ) {
                this.shopPayConfirm = false;
            }else{
                    if ( this.wechat_pay_key === 1 ) {
                        this.shopPayConfirm = false
                    }else{
                        this.shopPayConfirm = true
                    }
                    // this.shopPayConfirm = true;
            }
            let setInterval__ =   setInterval(() => {
                 $paymentQuery({ paytype: localStorage.getItem("type"),order_id: this.$route.params.id,}).then( (res) => {
                    this.is_pay = res.data.data.is_pay
                    if( res.data.data.is_pay ) {
                        setTimeout( () => {this.shopPayConfirm = true;},2000)
                        clearInterval(setInterval__)
                        let arr_from_ = ['hytonp1','nptohy1','kppd','cdpd','kphy','cdhy']
                        if( arr_from_.indexOf(this.$route.query.from) === -1 ) {
                            window.location.replace(`/name_matching/payment/succeed/${this.$route.params.id}/${localStorage.getItem("type")}?from=${this.$route.query.from}`)
                        }else{
                            //直接跳结果
                            window.location.replace(`/name_matching/payment/result/${this.$route.params.id}/${localStorage.getItem("type")}?from=${this.$route.query.from}`)
                        }
                        // window.location.replace(`/name_matching/payment/succeed/${this.$route.params.id}/${localStorage.getItem("type")}?from=${this.$route.query.from}`)
                    }else{
                        
                    }
                } )
            },1000)
            if (
                this.userinfo.order_status == "已完成" ||
                this.userinfo.order_status == "已支付"
            ) {
                this.$dialog.confirm({
                    message: "当前订单已支付",
                    showCancelButton: false,
                }).then(() => {
                    let arr_from_ = ['hytonp1','nptohy1','kppd','cdpd','kphy','cdhy']
                    if( arr_from_.indexOf(this.$route.query.from) === -1 ) {
                        window.location.replace(`/name_matching/payment/succeed/${this.$route.params.id}/${localStorage.getItem("type")}?from=${this.$route.query.from}`)
                    }else{
                        //直接跳结果
                        window.location.replace(`/name_matching/payment/result/${this.$route.params.id}/${localStorage.getItem("type")}?from=${this.$route.query.from}`)
                    }
                    // window.location.replace(`/name_matching/payment/succeed/${this.$route.params.id}/${localStorage.getItem("type")}?from=${this.$route.query.from}`)
                });
                return;
            }
            let data_ = { 
                    order_id:this.$route.params.id,
                    paytype: payment_type === "alipay" ? 1 : 2,
                }
                if ( localStorage.getItem('red_packet_type') === null) {
                    data_ = { 
                        order_id:this.$route.params.id,
                        paytype: payment_type === "alipay" ? 1 : 2,
                    }
                }else{
                    data_. discount_id =  1;
                    data_.discount_type = localStorage.getItem('red_packet_type');
                }
           
               
            $orderConfirm(data_).then( ( res ) => {
                if ( res.status === 200) {
                     if (this.is_weixn) {
                        // window.location.href=`https://api.zhiyileiju.cn/api/v1/wechat/entry/xx1/3`
					    // $payment(payment_type,this.$route.params.id).then( ( res ) => {
					    $payment(localStorage.getItem("is_hun_yin_").substr(1),this.$route.params.id).then( ( res ) => {
                            let pay = res.data.data;
                            wx.ready(function() {
                                wx.chooseWXPay({
                                    timestamp: pay.timeStamp,
                                    nonceStr: pay.nonceStr,
                                    package: pay.package,
                                    signType: pay.signType,
                                    paySign: pay.paySign, // 支付签名
                                    success: function(res) {
                                        let arr_from_ = ['hytonp1','nptohy1','kppd','cdpd','kphy','cdhy']
                                        if( arr_from_.indexOf(this.$route.query.from) === -1 ) {
                                            window.location.replace(`/name_matching/payment/succeed/${this.$route.params.id}/${localStorage.getItem("type")}?from=${this.$route.query.from}`)
                                        }else{
                                            //直接跳结果
                                            window.location.replace(`/name_matching/payment/result/${this.$route.params.id}/${localStorage.getItem("type")}?from=${this.$route.query.from}`)
                                        }
                                        // window.location.replace(`/name_matching/payment/succeed/${this.$route.params.id}/${localStorage.getItem("type")}?from=${this.$route.query.from}`)
                                    }
                                });
                            });
                            if ( this.wechat_pay_key === 1 ) {
                                this.shopPayConfirm = false
                            }else{
                                this.shopPayConfirm = true
                            }
                            this.wechat_pay_key++
                        })
					} else {
                        // console.log('payment_type',payment_type)
						location.href = `https://api.zhiyileiju.cn/api/v1/payment/${payment_type === 'wechat'? 'wechathy' :payment_type}/${this.$route.params.id}/${localStorage.getItem("is_hun_yin_").substr(1)}`;
                        this.shopPayConfirm = true
					}
                }
            });
            

        },
        timimgToast () {//每过5秒弹出用户支付的信息
            let num = Math.floor(Math.random()* this.data_list.length);
            if ( this.data_list.length !== 0) {
                let timer_payment = setInterval( () => {//没过5秒弹出用户购买记录
                    this.$toast({
                        // message:  this.data_list[num].name +'支付了'+ this.data_list[num].price+ "元" +this.data_list[num].designation,
                        message:  this.data_list[num].name +'支付了'+ this.price.pairname_amount + "元" +this.data_list[num].designation,
                        position: 'bottom',
                    });
                },5000)
                localStorage.setItem("timer_payment",timer_payment);
            }
        },
        //确认付款弹窗
        paymentButtoms() {//弹框的立即付款
            $stat("v1_name_atching_payment_immediate_payment")
            this.paymentButtom('')
        },
        Dcheckorder () { //已经付款buttom
            $stat("v1_name_atching_payment_account_paid")
            if ( this.$route.params.type !== '10' ){
                $paymentQuery({ paytype: this.$route.params.type,order_id: this.$route.params.id,}).then( (res) => {
                    this.is_pay = res.data.data.is_pay
                    if( this.is_pay ) {
                        let arr_from_ = ['hytonp1','nptohy1','kppd','cdpd','kphy','cdhy']
                        if( arr_from_.indexOf(this.$route.query.from) === -1 ) {
                            window.location.replace(`${localStorage.getItem("is_hun_yin_")}/payment/succeed/${this.$route.params.id}/${localStorage.getItem("type")}?from=${this.$route.query.from}`)
                        }else{
                            //直接跳结果
                            window.location.replace(`${localStorage.getItem("is_hun_yin_")}/payment/result/${this.$route.params.id}/${localStorage.getItem("type")}?from=${this.$route.query.from}`)
                        }
                        // window.location.replace(`${localStorage.getItem("is_hun_yin_")}/payment/succeed/${this.$route.params.id}/${localStorage.getItem("type")}?from=${this.$route.query.from}`)
                    }else{
                        this.paymentButtom('')
                        // this.$toast.fail("当前订单未支付");
                    }
                } ).catch( ( err ) => {
                    // this.$toast.fail("当前订单未支付");
                    this.paymentButtom('')
                    console.log('err',err)
                })
            }
           
        },
        unpay () {//领取红包
            localStorage.setItem("judge_indicates_which",localStorage.getItem('red_packet_type'));
            $stat("v1_name_atching_payment_non")
            // this.shopPayConfirm = false
            // this.$router.push(`${localStorage.getItem("is_hun_yin_")}`)
            this.$router.push('/'+this.$route.path.split('/')[1])
            if ( localStorage.getItem('red_packet_type') === '2' ) {
                window.localStorage.removeItem('red_packet_type');
                window.sessionStorage.removeItem('user_id');
                // window.location.replace(`/home_marriage_test?from=w3hc`)
                // if (this.is_weixn) {
                //     let from_arr_ = ['dbpd','xfpd','xfpd2','zbpd','rmpd1','rmpd2','dgpd'];
                //     if ( from_arr_.indexOf(this.$route.query.from) === -1) {
                //         window.location.replace(`https://wechat.nnabc.cn /api/v1/wechat/entry/${localStorage.getItem("fromk")}/4`)
                //     }else{
                //         window.location.replace(`https://jx2.gyqingqing.com/wechattestname?fromk=hjgw3cm`)
                //     }
                // }else{
                //     window.location.replace(`/home_marriage_test?from=w3hc`)
                // }
                this.saveTheLinkCallback()
                
            }else{
                this.$router.push('/'+this.$route.path.split('/')[1])
            }
        },
        complaintButtom(){//投诉
            $stat("v1_name_atching_payment_complaint");
            window.location.href = 'https://www.wjx.top/vm/hd0JNnc.aspx'
        },
    },
}

export default payment 
